<template>
  <form v-on:submit.prevent="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">Add Owner</h4>
    </div>

    <div class="modal-body">
      <div class="form-row">
        <div class="col mb-3">
          <label for="ownerName">Name <span class="red-dot ml-3"></span></label>
          <input id="ownerName" name="ownerName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('ownerName') }"
            autocomplete="off" placeholder="Enter name" v-validate="'required'" v-model="owner.name" data-vv-as="Name" />
          <div class="invalid-feedback">The Name field is required.</div>
        </div>
      </div>

      <div class="form-row">
        <div class="col mb-3">
          <label for="ownerStreet">Street</label>
          <input id="ownerStreet" name="ownerStreet" type="text" class="form-control" autocomplete="off" placeholder="Enter street" v-model="owner.address.street" />
        </div>
      </div>

      <div class="form-row">
        <div class="col mb-3">
          <label for="ownerCity">City</label>
          <input id="ownerCity" name="ownerCity" type="text" class="form-control"  autocomplete="off" placeholder="Enter city" v-model="owner.address.city" />
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="ownerProvince">Province <span class="red-dot ml-3"></span></label>
          <select id="ownerProvince" name="ownerProvince" class="form-control custom-select" :class="{ 'is-invalid': errors.has('ownerProvince') }"
              v-validate="'required'" v-model="owner.address.provinceId" data-vv-as="Province">
            <option v-for="province in getProvinces" :key="province.id" :value="province.id">{{ province.name }}</option>
          </select>
          <div class="invalid-feedback">The Province field is required.</div>
        </div>

        <div class="col-md-6 mb-3">
          <label for="ownerPostalCode">Postal Code</label>
          <input id="ownerPostalCode" name="ownerPostalCode" type="text" class="form-control" autocomplete="off" placeholder="Enter postal code"
            v-model="owner.address.postalCode" />
        </div>
      </div>

      <div class="form-row">
        <div class="col mb-3">
          <label for="ownerPhone">Phone</label>
          <input id="ownerPhone" name="ownerPhone" type="text" class="form-control" autocomplete="off" placeholder="Enter phone" v-model="owner.phone" />
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-success">Save</button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";

export default {
  name: 'contact-type',
  data: function () {
    return {
      isEdit: false,
      owner: {
        name: null,
        phone: null,
        address: {
          street: null,
          city: null,
          postalCode: null,
          provinceId: null
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getOwner", "getProvinces"])
  },
  mounted: function() {
    this.owner = { ...this.getOwner };
    this.isEdit = !!this.owner.id;
    this.$validator.reset();
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      // as we only handle name, remove this property to prevent name being reverted.
      delete this.owner.displayName;

      this.saveOwner({ data: this.owner, isEdit: this.isEdit })
        .then(() => {
          Toast.success(this, "Successfully saved!");
          this.$bvModal.hide("bv-modal-owner");
          this.$emit('save-action');
          this.closeModal();
        })
        .catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.closeModal();
        });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-owner");
    },
    ...mapActions(["saveOwner"])
  }
}
</script>
